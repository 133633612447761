



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Masthead extends Vue {
  private imageLoaded = false

  @Prop({ default: '/assets/images/masthead/background.svg' })
  private readonly image!: string;

  @Prop()
  private readonly title!: string;

  @Prop()
  private readonly subtitle!: string;

  @Prop({ default: 'center' })
  private readonly alignment!: string;

  @Prop({ default: 'normal' })
  private readonly height!: 'small' | 'normal' | 'large' | 'auto';

  @Prop({ default: false })
  private readonly fullSize!: boolean;
}
