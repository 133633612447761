





















import Utils from 'tradingmate-modules/src/Utils'
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class GetAQuoteBanner extends Vue {
  private getCharacter = Utils.getCharacter()
}

