






















































import { Vue, Component } from 'vue-property-decorator'

import Package1Card from './Package1Card.vue'
import Package2Card from './Package2Card.vue'
import Package3Card from './Package3Card.vue'
import Package4Card from './Package4Card.vue'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper'
import 'swiper/swiper-bundle.css'
import { Config } from 'tradingmate-modules/src/environment'

SwiperCore.use([Navigation, Pagination])

@Component({
  components: {
    Swiper,
    SwiperSlide,
    Package1Card,
    Package2Card,
    Package3Card,
    Package4Card
  }
})
export default class PricingCardCycler extends Vue {
  private selectedPackage = '1'

  private breaks = {
    600: {
      slidesPerView: 2,
      allowTouchMove: true,
      centeredSlides: true
    },
    900: {
      slidesPerView: 3,
      allowTouchMove: true,
      centeredSlides: true
    },
    1080: {
      slidesPerView: 4,
      allowTouchMove: false,
      centeredSlides: false,
      navigation: { enabled: false }
    }
  }

  private signup (packageNum: string): void {
    const consoleUrl = Config.WebsiteUrls.Console
    window.location.href = (consoleUrl + '/account/register' + '?p=' + packageNum)
  }

  mounted () {
    // this.loadListings();
  }
}
