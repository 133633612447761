
























import { Vue, Component } from 'vue-property-decorator'
import PricingCard from './PricingCard.vue'

@Component({
  components: {
    PricingCard
  }
})

export default class Package1Card extends Vue {
}

