








import Card from '@/components/layout/Card.vue'
import { BlogModel } from 'tradingmate-modules/src/models/api/blogs'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    Card
  }
})
export default class Blogcard extends Vue {
  @Prop({ required: true })
  private blog!: BlogModel

  get thumbnail (): string {
    let thumb = '/assets/images/placeholder/toolbox.jpg'
    if (this.blog.Images?.length) {
      thumb = this.blog.Images[0].Tiny
    }
    return thumb
  }

  get blogContent (): string {
    return this.blog.Content.slice(0, 128) + '...'
  }
}
