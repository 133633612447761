import { LogLevel } from 'tradingmate-modules/src/debug/Logger'
import { IConfig } from 'tradingmate-modules/src/environment'
import HttpProviderType from 'tradingmate-modules/src/services/httpclient/HttpProviderType'

export default {
  Http: {
    Provider: HttpProviderType.Fetch
  },
  API: {
    // Base: 'https://localhost:44300'
    Base: 'https://api.staging.tradingmate.com.au'
    // Base: 'https://api.tradingmate.com.au'
  },
  WebsiteUrls: {
    // Public: 'http://localhost:8080',
    // Console: 'http://localhost:8081',
    // Cdn: 'http://cdn.dev.tradingmate.com.au'

    Cdn: 'https://cdn.staging.tradingmate.com.au',
    Public: 'https://www.staging.tradingmate.com.au',
    Console: 'https://my.staging.tradingmate.com.au'

    // Cdn: 'https://cdn.tradingmate.com.au',
    // Public: 'https://www.tradingmate.com.au',
    // Console: 'https://my.tradingmate.com.au'
  },
  Logging: {
    LogLevel: LogLevel.Debug
  },
  OAuth: {
    // dev
    ConsumerId: 'd9b328d9-70e1-4ac9-abdc-7262daefb937'
    // live
    // ConsumerId: '9654d63c-b6a7-48c7-a27f-5ad730fab99a'
  }
} as IConfig
