














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class SideBySide extends Vue {
  @Prop({ default: false })
  private readonly isRowReversed!: boolean

  @Prop({ default: false })
  private readonly isColumnReversed!: boolean;

  @Prop({ default: 6 })
  private readonly displayWidth!: number;

  @Prop({ default: 4 })
  private readonly contentWidth!: number;
}
