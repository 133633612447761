








import { Vue, Component, Prop } from 'vue-property-decorator'
import Observer from '../status/Observer.vue'

@Component({
  components: {
    Observer
  }
})

export default class SlideFadeTransition extends Vue {
  private isVisible = false

  @Prop({ default: 'ltr' })
  private readonly direction!: 'ltr' | 'rtl' | 'utd' | 'dtu';
}

