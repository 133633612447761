






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop({ default: 'outlined' })
  private readonly type!: 'outlined' | 'filled'
}
