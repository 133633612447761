






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component
export default class Badge extends Vue {
  @Prop({ default: 'primary' })
  private readonly scheme!: string;

  @Prop({ default: false })
  private readonly isBold!: boolean;
}
