


































































































































































































































































































import SideBySide from '@/components/layout/SideBySide.vue'
import PricingCardCycler from '@/components/collections/memberships/PricingCardCycler.vue'
import BlogFilter from 'tradingmate-modules/src/filters/BlogFilter'
import { BlogModel, BlogPostStatus } from 'tradingmate-modules/src/models/api/blogs'
import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { PagedResultSet } from 'tradingmate-modules/src/models/api/common'
import { Services } from 'tradingmate-modules/src/services'
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Vue } from 'vue-property-decorator'
import BlogCard from '@/components/collections/blogs/BlogCard.vue'
import GridNav from '@/components/grid/GridNav.vue'
import GridNavItem from '@/components/grid/GridNavItem.vue'
import MemberCard from '../components/collections/member/MemberCard.vue'
import SearchBar from '../components/collections/member/SearchBar.vue'
import { Config } from 'tradingmate-modules/src/environment'
import Observer from '../components/status/Observer.vue'
import SlideFadeTransition from '@/components/transitions/SlideFadeTransition.vue'
import RecentBranches from '@/components/collections/home/RecentBranches.vue'
import GetAQuoteBanner from '@/components/layout/GetAQuoteBanner.vue'
import Packages from '@/components/collections/memberships/Packages.vue'
import NewsletterSignUp from '@/components/collections/home/NewsletterSignUp.vue'
import BlogType from '../../../tradingmate-modules/src/models/api/blogs/BlogType'

@Component({
  components: {
    NewsletterSignUp,
    Packages: Packages,
    GetAQuoteBanner,
    // Packages,
    SearchBar,
    GridNav,
    GridNavItem,
    MemberCard,
    BlogCard,
    PricingCardCycler,
    SideBySide,
    Observer,
    SlideFadeTransition,
    RecentBranches
  }
})

export default class Home extends Vue {
  private recentBlogs: PagedResultSet<BlogModel> | null = null;
  private popularCategories: CategoryModel[] | null = null;

  private showSideBySide1 = false;
  private showSideBySide2 = false;

  private getCharacter = Utils.getCharacter();

  private consoleUrl = Config.WebsiteUrls.Console

  mounted (): void {
    this.getRecentBlogs()
    this.getCategories()
  }

  private breaks = {
    600: {
      slidesPerView: 2,
      allowTouchMove: true,
      centeredSlides: true
    },
    900: {
      slidesPerView: 3,
      allowTouchMove: true,
      centeredSlides: true
    },
    1080: {
      slidesPerView: 4,
      allowTouchMove: false,
      centeredSlides: false,
      navigation: { enabled: false }
    }
  }

  private gettingRecentBlog = false
  getRecentBlogs (): void {
    this.gettingRecentBlog = true
    Services.API.Blogs.GetBlogsForPublic(new BlogFilter({
      Page: 1,
      PageSize: 15,
      Status: BlogPostStatus.Published,
      BlogType: BlogType.None
    })
    ).then(returnBlogs => {
      this.recentBlogs = returnBlogs
    }).finally(() => {
      this.gettingRecentBlog = false
    })
  }

  getCategories (): void {
    Services.API.Categories.GetPopular(6).then((returnCategories) => {
      this.popularCategories = returnCategories
    })
  }

  goToRegister (): void {
    window.open(this.consoleUrl + '/account/register', '_self')
  }
}
