









import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/layout/Header.vue'
import GetAQuoteBanner from '@/components/layout/GetAQuoteBanner.vue'
import Footer from '@/components/layout/Footer.vue'

import './assets/css/reset.css'
import './assets/css/colours.css'
import './assets/css/layout.css'
import './assets/css/font.css'

@Component({
  components: {
    Header,
    GetAQuoteBanner,
    Footer
  }
})
export default class App extends Vue {

}
