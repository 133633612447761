






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class VerticalHeading extends Vue {
  @Prop({ default: false })
  private readonly isReverse!: boolean;
}
