




























import Card from '@/components/layout/Card.vue'
import Badge from '@/components/status/Badge.vue'
import { BusinessBranchStatus } from 'tradingmate-modules/src/models/api/businesses'
import BusinessBranchCard from 'tradingmate-modules/src/models/api/businesses/BusinessBranchCard'
import { Component, Prop, Vue } from 'vue-property-decorator'
import ContactMethodType from 'tradingmate-modules/src/models/api/ContactMethodType'
import { CategoryModel } from '../../../../../tradingmate-modules/src/models/api/categorisation'

@Component({
  components: {
    Badge,
    Card
  }
})
export default class MemberCard extends Vue {
  @Prop({ required: true })
  private readonly member!: BusinessBranchCard

  @Prop({ default: null })
  private readonly preferredCategoryIds!: string[] | null

  private inactive = BusinessBranchStatus.Inactive

  preferredCategory (): CategoryModel | null {
    const intersection = this.member.Categories.filter(value => this.preferredCategoryIds?.includes(value.CategoryId))
    if (intersection.length) {
      return intersection[0]
    } else if (this.member.Categories[0]) {
      return this.member.Categories[0]
    } else {
      return null
    }
  }

  get memberPhone (): string | null {
    let phone = this.member.ContactMethods.find(contact => contact.ContactMethodType === ContactMethodType.MobilePhone)?.Value ?? null
    phone ??= this.member.ContactMethods.find(contact => contact.ContactMethodType === ContactMethodType.Landline)?.Value ?? null
    return phone
  }

  get memberEmail (): string | null {
    return this.member.ContactMethods.find(contact => contact.ContactMethodType === ContactMethodType.Email)?.Value ?? null
  }

  get thumbnail (): string {
    let thumb = '/assets/images/brand/placeholder.jpg'
    if (this.member.Logo?.Tiny) {
      thumb = this.member.Logo.Tiny
    } else if (this.member.GalleryImages && this.member.GalleryImages[0]?.Tiny) {
      thumb = this.member.GalleryImages[0].Tiny
    }
    return thumb
  }
}
