














import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class GridNavItem extends Vue {
  @Prop({ required: true })
  private readonly category!: CategoryModel
}
