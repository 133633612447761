






import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Heading extends Vue {
  @Prop({ default: 'h3' })
  private readonly level!: string;

  @Prop({ default: false })
  private readonly bold!: boolean;

  @Prop({ default: false })
  private readonly isWhite!: boolean;
}
