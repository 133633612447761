


























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Form extends Vue {
  @Prop({ default: '' })
  private readonly title!: string;

  @Prop()
  private readonly icon!: string;

  @Prop()
  private readonly hint!: string;
}
