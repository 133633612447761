






import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
  }
})

export default class FlexRow extends Vue {
  @Prop({ default: 'medium' })
  private readonly spacing!: 'small' | 'medium' | 'large';

  @Prop({ default: 'flex-start' })
  private readonly justify!: 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly';
}

